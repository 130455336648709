import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { defaults } from 'src/constants/constants';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentReference, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

export interface Body {
  id?: string;
  goal?: string;

  academyId?: string; // active academy

  gender?: string;
  age?: number;
  dateOfBirth?: Date;

  // Ability body
  level: number; // 0 = beginner, 1 = novice, 2 = intermediate, 3 = advanced (top 5%), 4 = elite (top 2.5%)
  stw: number; // Strength-to-Weight Ratio
  cardio: number; // 0.5 = below average     1 = average     1.5 above average
  strength: number;
  mobility: number;

  intensity: number; // 4 to 10 (40% to 100%, defaults to 70%)

  weight: number;
  height: number;

  // TODO: Remove these static properties and use array of objects for more flexibility
  jointIssues?: []; // joint name
  soreMuscles?: []; // muscle name

  areasOfFocus?: []; // [exercise.muscle, exercise.type (stretch|strength|cardio)]

  solution?: string;
  categoriesOfFocus?; // {key:value} or {string:boolean} but unsure if this is valid type

  equipment?: {}[];
  notifications?: {}[];

  solutionFeelBetter?: string; // feel better
  solutionPlayBetter?: string; // play better
  solutionMoveBetter?: string; // move better

  notificationsLastChangedAt?: number;
  daysOfWeek?: boolean[];


  emailNotificationsEnabled?: boolean;
  smsNotificationsEnabled?: boolean;
  pushNotificationsEnabled?: boolean;

  bundlePrograms?;
  specialist?;
}

@Injectable()
export class BodyService {
  defaults = defaults;

  userId: string;
  private docRef: DocumentReference;
  // private body = new BehaviorSubject(this.getDefault());
  private body = new BehaviorSubject(null);



  collection = "body";
  private bodyCollection: AngularFirestoreCollection<any>;
  private bodies: Observable<any[]>;




  getDefault(): Body {
    return {
      // Static
      gender: 'm',
      // dateOfBirth: new Date(),

      // Dynamic
      weight: 185, // lb
      height: 181, // cm

      // Ability body
      level: 1, // 0 = beginner, 1 = novice, 2 = intermediate, 3 = advanced (top 5%), 4 = elite (top 2.5%)
      stw: 1.1, // Strength-to-Weight Ratio
      cardio: 1, // 0.5 = below average     1 = average     1.5 above average
      strength: 1,
      mobility: 1,

      intensity: 7,

      // join issues
      // jointIssues: [], // String array of joint names

      // Sore muscles
      // soreMuscles: [], // muscle

      // areas of focus
      areasOfFocus: [], // [exercise.muscle, exercise.type (stretch|strength|cardio)]

      pushNotificationsEnabled: true,
      emailNotificationsEnabled: false,
      smsNotificationsEnabled: false,
    };
  }

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) {
    // console.log('%c Body Service instantiated.', defaults.styles.component);
    afAuth.authState.subscribe((auth) => {
      if (auth) {
        this.userId = auth.uid;
        // console.log('%c Authorized User id:', defaults.styles.authorized, this.userId);
        console.log('%c TODO: Authenticated users Body document retrieved on first load incorrectly. Might cause issues. Please fix.', defaults.styles.todo); // TODO
        this.getDataById(this.userId); // TODO: Old way of automatically getting body isn't a good idea.
        this.init();
      }
    });
  }


  init(collection?) {
    if (collection) this.collection = collection;
    // console.log('COLLECTION?', this.collection);
    this.bodyCollection = this.db.collection<any>(this.collection);
    this.bodies = this.bodyCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    // , first());
  }

  getItems() {
    return this.bodies;
  }

  getItem(id) {
    return this.bodyCollection.doc<any>(id).valueChanges();
  }

  updateItem(body: any, id: string) {
    console.log('%c Update body for user: ' + this.userId, defaults.styles.writing, id);
    return this.bodyCollection.doc(id).update(body);
  }

  addItem(body: any) {
    return this.bodyCollection.add(body);
  }

  removeItem(id) {
    return this.bodyCollection.doc(id).delete();
  }








  getDataById(userId: string): Observable<Body> {
    // console.log('%c Load body for user', defaults.styles.loaded, userId);
    if (!userId) {
      console.log('%c userId Undefined. Failed to Load body document', defaults.styles.error, userId);
      return;
    }
    this.docRef = this.db.firestore.doc('/body/' + userId);

    this.docRef.get().then((doc) => {
      if (doc.exists) {
        const body = doc.data() as Body;
        // console.log('%c Body data found', defaults.styles.loaded, body);
        // console.log('Document data:', body.data());
        // this.body = body.data() as Body;
        return this.body.next(body);
      } else {
        // body.data() will be undefined in this case
        console.warn('%c Body not found for user! Might be first time, creating new body for user: ' + userId, defaults.styles.fresh, this.body);
        this.body.next(this.getDefault());
        this.setData(this.body);
      }
    }).catch((error) => {
      console.log('Error getting document:', error);
    });
    return this.body.asObservable();
  }

  // getBodyById(userId: string){
  //   console.log('%c Load body for user', defaults.styles.loaded, userId);
  //   this.docRef = this.db.firestore.doc('/body/' + userId);

  //   this.docRef.get().then((doc) => { 
  //     console.log('%c Body data returned', defaults.styles.loaded);
  //     if (doc.exists) {
  //       const body = doc.data() as Body;
  //       // console.log('Document data:', body.data());
  //       // this.body = body.data() as Body;
  //       return this.body;
  //     } else {
  //       // body.data() will be undefined in this case
  //       console.warn('%c Body not found for user! Might be first time, creating new one.', defaults.styles.fresh, userId);
  //       this.setData(this.getDefault());
  //     }
  //   }).catch((error) => {
  //     console.log('Error getting document:', error);
  //   });
  //   return this.body.asObservable();
  // }

  setData(body) {
    if (!body) {
      console.log('%c Body data failed to save. Body undefined!', defaults.styles.error, body);
      return;
    }
    if (!body.hasOwnProperty("gender")) {
      // TODO: This issue is caused by improper use of Observables in the above function getDataById() when body object has not been created yet (user never enrolled)
      console.log('%c Body object malformed! We might be passing an observable.', defaults.styles.heal, body);
      return;
    }
    console.log('%c Body data saving...', defaults.styles.writing, body);
    return this.db.collection('body').doc(this.userId)
      .set(body, { merge: true })
      .catch((reason) => {
        console.log("%c Failed to save Body data.", defaults.styles.error);
      });
  }

  public getStrengthToWeightRatio(body) {
    // const body = this.body;
    if (body.stw < 1) {
      return 'Beginner';
    } else if (body.stw >= 1 && body.stw < 1.4) {
      return 'Novice';
    } else if (body.stw >= 1.4 && body.stw < 1.8) {
      return 'Intermediate';
    } else if (body.stw >= 1.8 && body.stw < 2.2) {
      return 'Advanced';
    } else if (body.stw >= 2.2 && body.stw <= 2.5) {
      return 'Elite';
    }
  }

  public getLevelOfActivity(level) {
    const levels = ['Sedentary', 'Walking', 'Active', 'Exercise', 'Athletic'];
    return levels[level];
  }

}
