// REQUIRED (after moving most academy fields from constants.ts to the /academy document in Firestore)
// name: 'Third Eye Global',
// startupRoute: 'play',
// defaultAcademy: 'LGzpWytfchYLtj1ptovZkpUa98m2',

interface Academy {
  name: string;
  startupRoute: string;
  defaultAcademy: string;
  firebaseProject: string;
  stripeKeyLive?: string;
  stripeKeyTest?: string;
}

interface Academies {
  hackYourHuman: Academy;
  saga: Academy;
  sagalifeapp: Academy;
  sevenMovementsResearch: Academy;
  sevenMovements: Academy;
  thirdEyeGlobal: Academy;
  dayInALife: Academy;
  mostConnected?: Academy;
  elkAndOwl?: Academy;
}

export const academies: Academies = {

  //
  // HACK YOUR HUMAN
  hackYourHuman: {
    name: 'Hack Your Human',
    startupRoute: 'play',
    defaultAcademy: 'ugWavqSLcOReqV9KpVcg0Vpz4k53',
    firebaseProject: "hackyourhuman",
  },
  
  //
  // SAGA TECH (Third Eye Global)
  saga: {
    name: 'SAGA',
    startupRoute: 'play',
    defaultAcademy: 'ugWavqSLcOReqV9KpVcg0Vpz4k53',
    firebaseProject: "saga-tech",
    stripeKeyLive: "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", // TODO: Review... This might still be the old saga-tech stripe account key (used to be "sk_live_2y1H0ECTHuBL12AzyJs6QdnY")
    stripeKeyTest: "sk_test_51O1xf2ISqeGc3GaYiH5hjaVi6bDtdy8Xp51p23jXWSvWpMK4FFsr7G5wg2HRz9RngWhCjMJu35Q6mm5wXAjLou11008YoR29i7", // TODO: Review... This might still be the old saga-tech stripe account key (used to be "sk_test_T13mJNtQzJoCRbHrNrflqOMC")
  },

  //
  // SAGA LIFE
  sagalifeapp: {
    name: 'SAGA LIFE',
    startupRoute: 'play',
    defaultAcademy: 'ugWavqSLcOReqV9KpVcg0Vpz4k53',
    firebaseProject: "sagalifeapp"
  },

  //
  // SEVEN MOVEMENTS RESEARCH (hackyourhuman)
  sevenMovementsResearch: {
    name: 'Seven Movements Research',
    startupRoute: 'play',
    defaultAcademy: 'MoNWanoNRSPLwwJyTwf2eMhcH4y2',
    firebaseProject: "hackyourhuman",
  },

  //
  // SEVEN MOVEMENTS
  sevenMovements: {
    name: 'Seven Movements',
    startupRoute: 'play',
    defaultAcademy: '3nkS8sO6v2WpJh4EEiFAz94KxEJ2',
    // firebaseProject: "hackyourhuman", // INACTIVE (OLDER COPY, 1st INSTANCE)
    // firebaseProject: "saga-tech", // INACTIVE (OLD COPY, 2nd INSTANCE)
    firebaseProject: "seven-movements", // ACTIVE FIREBASE PROJECT (3rd INSTANCE)
    stripeKeyLive: "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",
    stripeKeyTest: "sk_test_51LIddwCjTYqSulaokAmEABhg3l1q6WYV9PKUVYHZzpnyW3egLnFBohDFyo5b4SywN8V4UAE0DlC97EDMCgrOeDnd00ofHvfWaC",
  },

  //
  // 3EG
  thirdEyeGlobal: {
    name: 'Third Eye Global',
    startupRoute: 'play',
    defaultAcademy: 'LGzpWytfchYLtj1ptovZkpUa98m2',
    firebaseProject: "saga-tech",
    stripeKeyLive: "pk_live_51O1xf2ISqeGc3GaYf1N4P3Hc3knNBSIKBibU3YT0bI4h6TIP3oh9bHK4GeNqQ0NWD8PikC94mUu1qyanHbunhYK500vbXDmMVz", // 3EG Stripe Account Keys (confirmed by Igor on Oct 15 2024)
    stripeKeyTest: "pk_test_51O1xf2ISqeGc3GaY8Ef6LJTx2wfk2pf95oHRzLmEvftPRG6UDqW6UoW8Zfw296o4v8hZD8B100kZVgz2fr2HeuQx00vfBj4RsX", // 3EG Stripe Account Keys (confirmed by Igor on Oct 15 2024)
  },

  //
  // DAY IN A LIFE
  dayInALife: {
    name: 'Day In A Life',
    startupRoute: 'play',
    defaultAcademy: '_owner',
    firebaseProject: "day-in-life-app",
  },

  //
  // MOST CONNECTED
  mostConnected: {
    name: 'Most Connected',
    startupRoute: 'play',
    defaultAcademy: 'kO4L6z9qMdhtu658BwV77KW2F5q1',
    firebaseProject: "most-connected",
  },

  //
  // ELK AND OWL
  elkAndOwl: {
    name: 'Elk and Owl',
    startupRoute: 'play',
    defaultAcademy: 'mophnOAqRrMFgPRCNK2Mu7LCgjN2',
    firebaseProject: "elk-and-owl",
  },
}